import { DateTime } from 'luxon'

import { DATE_FORMAT } from '@/open-web/utils/constants'
import type { PriceType } from '@/shared/graphql/schema/commonBackend/graphql'

const PRICE_TYPE_START_DATE_OFFSET: Record<
  Extract<PriceType, 'FIXED' | 'HYBRID' | 'SPOT' | 'FLAT_RATE'>,
  number
> = {
  FIXED: 16,
  HYBRID: 16,
  SPOT: 5,
  //TODO: Check that value
  FLAT_RATE: 16,
} as const

//TODO: Apply logic base on the overwrites from campaign configuration
export const getDefaultStartDateByPriceType = (priceType: PriceType) => {
  if (
    priceType !== 'FIXED' &&
    priceType !== 'SPOT' &&
    priceType !== 'HYBRID' &&
    //TODO: Make this method country specific
    priceType !== 'FLAT_RATE'
  ) {
    throw new Error(`Unknown priceType: ${priceType}`)
  }

  const offset = PRICE_TYPE_START_DATE_OFFSET[priceType]

  return DateTime.now().plus({ days: offset }).toFormat(DATE_FORMAT)
}
